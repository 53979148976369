<template lang="pug">
.ds-editor-pane-layout-wrapper
  .ds-editor-pane-layout.design-system(
    :class="layoutClasses"
    :style="{ gap: layout === 'row' ? gap : null }"
  )
    .ds-editor-pane-layout-left
      slot(name="left")
    .ds-editor-pane-layout-middle(v-if="$slots.middle")
      slot(name="middle")
    .ds-editor-pane-layout-right
      slot(name="right")
  .ds-editor-pane-layout-error(v-if="$slots.error")
    slot(name="error")
  .ds-editor-pane-layout-help(v-if="$slots.help")
    slot(name="help")
</template>

<script>
  export default {
    props: {
      layout: {
        type: String,
        default: 'row',
        options: ['row', 'col'],
        validator(value) {
          return ['row', 'col'].includes(value);
        },
      },
      layoutRatio: {
        type: String,
        default: 'half',
        options: ['half'],
        validator(value) {
          return ['half'].includes(value);
        },
      },
      gridLayout: {
        type: String,
        default: () => null,
      },
      gap: {
        type: String,
        default: '12px',
      },
    },
    computed: {
      isRow() {
        return this.layout === 'row';
      },
      layoutClasses() {
        const list = [];

        if (!this.isRow && this.gridLayout) list.push(`grid-template-${this.gridLayout}`);
        if (!this.isRow && this.$slots.middle) list.push(`grid-template-third`);
        if (!this.isRow && !this.$slots.middle && !this.gridLayout) list.push(`grid-template-half`);

        if (!this.isRow) list.push('align-items-center');

        return list;
      },
    },
  };
</script>

<style lang="sass">
  .ds-editor-pane-layout
    display: grid
    &.grid-template-third
      grid-template-columns: 1fr 1fr 1fr
    &.grid-template-half
      grid-template-columns: 1fr 1fr
    &.grid-template-two-third
      grid-template-columns: 2fr 1fr
    &.grid-template-one-third
      grid-template-columns: 1fr 2fr
    &-error
      color: red
</style>
